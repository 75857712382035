import { Component, inject, Input, OnInit } from '@angular/core';
import {
  Auth,
  MultiFactorInfo,
  PhoneAuthProvider,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
} from '@angular/fire/auth';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-otp',
  standalone: true,
  imports: [TranslateModule],
  templateUrl: './otp.component.html',
  styleUrl: './otp.component.scss',
})
export class OtpComponent implements OnInit {
  @Input() hints: PhoneMultiFactorInfo[];
  auth = inject(Auth);
  constructor() {}

  ngOnInit() {
    console.log('hints', this.hints);
  }

  OTPInput() {
    const inputs: any = document.querySelectorAll('#otp > *[id]');
    /* for (let i = 0; i < inputs.length; i++) {
      inputs[i].addEventListener('keydown', (event: any) => {
        if (event.key === 'Backspace') {
          inputs[i].value = '';
          if (i !== 0) inputs[i - 1].focus();
        } else {
          if (i === inputs.length - 1 && inputs[i].value !== '') {
            return true;
          } else if (event.keyCode > 47 && event.keyCode < 58) {
            inputs[i].value = event.key;
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          } else if (event.keyCode > 64 && event.keyCode < 91) {
            inputs[i].value = String.fromCharCode(event.keyCode);
            if (i !== inputs.length - 1) inputs[i + 1].focus();
            event.preventDefault();
          }
        }
      });
    } */
  }
}
