<!-- <nav class="navbar fixed-bottom bg-body-tertiary">
  <div class="container-fluid">
    <a class="navbar-brand" href="https://gerichtskostenmarke.de" target="_blank">&copy; 2024 GoComply GmbH</a>
    <a class="navbar-brand" href="https://gerichtskostenmarke.de/impressum" target="_blank">Impressum</a>
  </div>
</nav> -->

<nav class="navbar fixed-bottom bg-body-tertiary">
  <div class="container-fluid">
    <div class="col-md-7 col-lg-7 col-sm-12">
      <img src="assets/images/logo_new_1.png" class="logo img-fluid pe-2 " alt>

    </div>
    <div class="col-lg-3 col-md-3 col-sm-12">
      <select name="countries" (change)="changeLang($event)">
        <option value="en" [selected]="bs.currentLang == 'en'">🇬🇧</option>
        <option value="de" [selected]="bs.currentLang == 'de'">🇩🇪</option>
      </select>
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12">

      <a class="navbar-brand float-end fs-6"
        href="https://gerichtskostenmarke.de/impressum"
        target="_blank">Impressum</a>
    </div>
    <!-- <a class="navbar-brand" href="https://gerichtskostenmarke.de" target="_blank">&copy; 2024 GoComply GmbH</a> -->
    <!-- <span class="w-80">
    </span>
    <span class="w-20">
    </span> -->
  </div>
</nav>