import { Component, inject } from '@angular/core';
import { BackendService } from '../../../service/backend.service';

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss',
})
export class FooterComponent {
  public bs = inject(BackendService);

  constructor() {}

  changeLang(e: Event) {
    const lang = (e.target as HTMLSelectElement).value;
    this.bs.switchLanguage(lang);
  }
}
