import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../service/auth.service';
import { filter, firstValueFrom, Observable, of, tap } from 'rxjs';
import { RouterLink } from '@angular/router';
import { FirestoreService } from '../../../service/firestore.service';
import { CommonModule } from '@angular/common';
import { Customer } from '../../../core/models/customer';
import { DocumentData } from '@angular/fire/firestore';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-navbar',
  standalone: true,
  imports: [RouterLink, CommonModule, TranslateModule],
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
})
export class NavbarComponent implements OnInit {
  navbarOpen = false;
  contactData: Customer | any = {};
  subscriptions: any = [];
  office: any = null;
  constructor(public auth: AuthService, private fs: FirestoreService) {}

  async ngOnInit() {
    await this.auth.waitForUser();
    this.contactData = await firstValueFrom(this.fs.getUserInformation());

    if (this.contactData && this.contactData.contact && this.auth.officeid) {
      this.office = await firstValueFrom(this.fs.getOffice(this.auth.officeid));
    }
    this.subscriptions = await firstValueFrom(
      this.fs.getSubscriptions('navbar')
    );

    //this.fs.getUserInformation("navbar").subscribe((data: any) => this.contactData = data);
  }
}
