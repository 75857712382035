import { EventEmitter, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { Utilities } from '@microsoft/office-js-helpers';

@Injectable({
  providedIn: 'root',
})
export class BackendService {
  showLoading$: EventEmitter<any> = new EventEmitter();
  currentLang = 'de';
  isOfficeApplication = false;

  constructor(private translate: TranslateService) {
    if (
      Utilities.host === 'EXCEL' ||
      Utilities.host === 'WORD' ||
      Utilities.host === 'POWERPOINT'
    ) {
      this.isOfficeApplication = true;
    }
  }

  public async notification(
    title: string,
    message: string,
    type: 'success' | 'error' | 'warning' | 'info' | 'question' = 'success'
  ) {
    return Swal.fire(title, message, type);
  }

  switchLanguage(lang: string) {
    this.currentLang = lang.toLowerCase();
    this.translate.use(this.currentLang);
  }
}
