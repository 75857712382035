import { inject, Injectable } from '@angular/core';
import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  collectionData,
  connectFirestoreEmulator,
  deleteDoc,
  doc,
  docData,
  Firestore,
  getAggregateFromServer,
  getCountFromServer,
  getDoc,
  getFirestore,
  limit,
  orderBy,
  Query,
  query,
  setDoc,
  sum,
  updateDoc,
  where,
} from '@angular/fire/firestore';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import {
  catchError,
  combineLatest,
  firstValueFrom,
  map,
  of,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { Office } from '../core/models/office';
import { Customer } from '../core/models/customer';
import moment from 'moment';
import { connectDatabaseEmulator, Database } from '@angular/fire/database';
@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private db: Firestore = inject(Firestore);
  private rdb: Database = inject(Database);
  private subscriptionRef = collection(this.db, 'subscriptions');

  constructor(private auth: AuthService) {
    if (environment.useEmulators.firestore) {
      connectFirestoreEmulator(this.db, 'localhost', 8080);
      console.log('db', this.db);
    }
    if (environment.useEmulators.database) {
      connectDatabaseEmulator(this.rdb, 'localhost', 9000);
    }
  }

  getUserInformation() {
    const docRef = doc(this.db, `users/${this.auth.user?.uid}`);
    return docData(docRef).pipe(
      shareReplay(1)
      //tap(() => console.log('getUserInformation',from))
    );
  }

  getOfficeInformation(officeId: string) {
    const docRef = doc(this.db, `offices/${officeId}`);
    return docData(docRef).pipe(
      shareReplay(1),
      tap(() => console.log('getOfficeInformation'))
    );
  }

  updateUserInformation(user: any) {
    const docRef = doc(this.db, `users/${this.auth.user?.uid}`);
    return setDoc(docRef, Object.assign({}, user), { merge: true });
  }

  updateContactInformation(contact: any) {
    const docRef = doc(this.db, `users/${this.auth.user?.uid}`);
    return setDoc(docRef, Object.assign({}, { contact: contact }), {
      merge: true,
    });
  }

  createOffice(office: Office) {
    const colRef = collection(this.db, 'offices');
    return addDoc(colRef, Object.assign({}, office));
  }
  getOffice(officeId: string) {
    const docRef = doc(this.db, `offices/${officeId}`);
    return docData(docRef, { idField: 'id' }).pipe(shareReplay(1));
  }
  addSubscription(subscription: any) {
    return addDoc(this.subscriptionRef, Object.assign({}, subscription));
  }

  deleteSubscription(subscriptionId: string) {
    const docRef = doc(this.db, 'subscriptions', subscriptionId);
    return deleteDoc(docRef);
  }

  updateSubscription(subscription: any) {
    const docRef = doc(this.db, 'subscriptions', subscription.id);
    return setDoc(docRef, Object.assign({}, subscription), { merge: true });
  }

  getProducts() {
    const colRef = collection(this.db, 'products');
    const q = query(colRef, where('active', '==', true));
    return collectionData(q, { idField: 'id' });
  }
  getProductPrice(productId: string) {
    const docRef = collection(this.db, `products/${productId}/prices`);
    const q = query(docRef, where('active', '==', true), limit(1));
    return collectionData(q, { idField: 'id' });
  }
  saveCreditCard(officeId: string, creditCard: any) {
    const colRef = collection(this.db, `offices/${officeId}/creditcards`);
    return addDoc(colRef, Object.assign({}, creditCard));
  }
  updateCreditCard(officeId: string, creditCard: any) {
    const docRef = doc(
      this.db,
      `offices/${officeId}/creditcards/${creditCard.id}`
    );
    return setDoc(docRef, Object.assign({}, creditCard), { merge: true });
  }

  giveAccessToCreditCard(
    officeId: string,
    creditCardId: string,
    employeeId: string
  ) {
    const docRef = doc(
      this.db,
      `offices/${officeId}/creditcards/${creditCardId}`
    );
    return updateDoc(docRef, { accessTo: arrayUnion(employeeId) });
  }

  deleteAccessToCreditCard(
    officeId: string,
    creditCardId: string,
    employeeId: string
  ) {
    const docRef = doc(
      this.db,
      `offices/${officeId}/creditcards/${creditCardId}`
    );
    return updateDoc(docRef, { accessTo: arrayRemove(employeeId) });
  }

  deleteCreditCard(officeId: string, creditCardId: string) {
    const docRef = doc(
      this.db,
      `offices/${officeId}/creditcards/${creditCardId}`
    );
    return deleteDoc(docRef);
  }

  getCreditCards() {
    if (!this.auth.officeid) return of([]);

    const colRef = collection(
      this.db,
      `offices/${this.auth.officeid}/creditcards`
    );
    let q: Query | null = null;
    if (this.auth.roles.includes('owner')) {
      q = query(colRef);
    } else {
      q = query(
        colRef,
        where('accessTo', 'array-contains', this.auth.user?.uid)
      );
    }
    return collectionData(q, { idField: 'id' });
  }

  getCreditCard(creditCardId: string) {
    //if(!this.auth.officeid || !this.auth.user?.uid) return of(null);

    /* const colRef = collection(this.db, `offices/${this.auth.officeid}/creditcards`)
    let q: Query = query(colRef);
    q = query(colRef, where("accessTo", "array-contains", this.auth.user!.uid))
    //const q = query(colRef, where("accessTo", "array-contains", this.auth.user?.uid))
    if(this.auth.roles.includes("owner")) {
    }
    return collectionData(q, {idField: 'id'}); */
    const docRef = doc(
      this.db,
      `offices/${this.auth.officeid}/creditcards/${creditCardId}`
    );
    return docData(docRef, { idField: 'id' }).pipe(shareReplay(1));
  }
  addEmployee(officeId: string, employee: any) {
    const colRef = doc(this.db, `offices/${officeId}/employees/${employee.id}`);
    return setDoc(colRef, Object.assign({}, employee), { merge: true });
  }
  updateEmployee(officeId: string, employee: any) {
    const colRef = doc(this.db, `offices/${officeId}/employees/${employee.id}`);
    return setDoc(colRef, Object.assign({}, employee), { merge: true });
  }
  inviteEmployee(officeId: string, employee: any) {
    const officeRef = doc(this.db, `offices/${officeId}/employees`);
    return setDoc(officeRef, employee, { merge: true });
  }
  getInvites(officeId: string) {
    const colRef = collection(this.db, `invites`);
    const q = query(
      colRef,
      where('officeId', '==', officeId),
      orderBy('createAt', 'desc')
    );
    return collectionData(q, { idField: 'id' });
  }
  getEmployees(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/employees`);
    const q = query(colRef, orderBy('active', 'desc'));
    return collectionData(q, { idField: 'id' });
  }
  getSubscriptions(from?: any) {
    const colRef = collection(
      this.db,
      `users/${this.auth.user?.uid}/subscriptions`
    );
    const q = query(colRef, where('status', '==', 'active'));
    return collectionData(q, { idField: 'id' }).pipe(shareReplay(1));
  }

  getOfficeSubscription() {
    const docRef = doc(this.db, `offices/${this.auth.officeid}`);
    return getDoc(docRef);
  }
  getInvoices(subId: string) {
    const colRef = collection(
      this.db,
      `users/${this.auth.user?.uid}/subscriptions/${subId}/invoices`
    );
    return collectionData(colRef, { idField: 'id' });
  }
  getCourtCosts() {
    const colRef = collection(this.db, `court_costs`);
    const q = query(colRef, orderBy('disputed_value', 'asc'));
    return collectionData(q, { idField: 'id' }).pipe(shareReplay(1));
  }

  getKostenmarken() {
    if (!this.auth.officeid) {
      return of(null);
    }
    const colRef = collection(
      this.db,
      `offices/${this.auth.officeid}/kostenmarken`
    );
    let q: Query | null = null;
    if (this.auth.roles.includes('owner')) {
      q = query(
        colRef,
        where('status', '==', 'done'),
        orderBy('created', 'desc')
      );
    } else {
      q = query(
        colRef,
        where('status', '==', 'done'),
        where('creator', '==', this.auth.user?.uid),
        orderBy('created', 'desc')
      );
    }
    return collectionData(q, { idField: 'id' }).pipe(
      map((marken) =>
        marken.map((marke: any) => {
          return {
            ...marke,
            createAt: moment.unix(marke.created).format('DD.MM.YYYY'),
          };
        })
      ),
      /*switchMap((marken: any) => {
        const markenObs = marken.map((marke: any) =>
          this.getCreditCard(officeId, marke.creditCardId).pipe(
            map((card: any) => {
              if (card) {
                return { ...marke, usedCreditCard: card.owner };
              } else {
                return { ...marke, usedCreditCard: '' };
              }
            })
          )
        );
        return combineLatest(markenObs);
      }),*/
      catchError((error) => {
        console.error('Error getting documents: ', error);
        return of(null);
      })
    );
  }

  getKostenmarkeByMonth(officeId: string, month?: number) {
    const colRef = collection(this.db, `offices/${officeId}/kostenmarken`);

    let firstDayOfMonthEpoch = moment().startOf('month').unix();
    let lastDayOfMonthEpoch = moment().endOf('month').unix();
    if (month) {
      firstDayOfMonthEpoch = moment()
        .month(month - 1)
        .startOf('month')
        .unix();
      lastDayOfMonthEpoch = moment()
        .month(month - 1)
        .endOf('month')
        .unix();
    }
    const q = query(
      colRef,
      where('createAt', '>=', firstDayOfMonthEpoch),
      where('createAt', '<=', lastDayOfMonthEpoch),
      orderBy('createAt', 'desc')
    );
    return getCountFromServer(q);
    //return collectionData(q, { idField: 'id' });
  }

  totalSumCourtFees(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/kostenmarken`);
    return getAggregateFromServer(colRef, {
      totalFee: sum('courtFee'),
    });
  }
  totalSumCourtFeesCurrentMonth(officeId: string) {
    const colRef = collection(this.db, `offices/${officeId}/kostenmarken`);
    const firstDayOfMonthEpoch = moment().startOf('month').unix();
    const lastDayOfMonthEpoch = moment().endOf('month').unix();
    const q = query(
      colRef,
      where('createAt', '>=', firstDayOfMonthEpoch),
      where('createAt', '<=', lastDayOfMonthEpoch)
    );
    return getAggregateFromServer(q, {
      totalFee: sum('courtFee'),
    });
  }

  createKostenmarke(officeId: string, kostenmarke: any) {
    const colRef = collection(this.db, `offices/${officeId}/kostenmarken`);
    return addDoc(colRef, Object.assign({}, kostenmarke));
  }

  getKostenmarke(officeId: string, kostenmarkeId: string) {
    const docRef = doc(
      this.db,
      `offices/${officeId}/kostenmarken/${kostenmarkeId}`
    );
    return docData(docRef, { idField: 'id' }).pipe(shareReplay(1));
  }

  setKanzlei(officeId: string, kanzlei: any) {
    const docRef = doc(this.db, `offices/${officeId}`);
    return setDoc(docRef, Object.assign({}, kanzlei), { merge: true });
  }
  async createCheckoutSession(product: any) {
    const user: Customer | any = await firstValueFrom(
      this.getUserInformation()
    );

    let sessionObj = {
      mode: product.price[0].type == 'recurring' ? 'subscription' : 'payment',
      client_reference_id: this.auth.user?.uid,
      customer: user.stripeId,
      locale: 'de',
      line_items: [
        {
          price: product.price[0].id,
          quantity: 1,
        },
      ],
      automatic_tax: {
        enabled: true,
      },
      promotion_code:
        product.price[0].type == 'recurring'
          ? null
          : 'promo_1PPiOkBmzLI87PzTqUyYyB8g',
      allow_promotion_codes:
        product.price[0].type == 'recurring' ? false : true,
      /* metadata: {
        trial_period_days: 30,
        trial_settings: {
          end_behavior: {
            missing_payment_method: 'cancel',
          },
        }
      }, 
      payment_method_collection: 'if_required',*/
      expires_at: Math.floor(Date.now() / 1000) + 60 * 45,
      success_url: window.location.origin + '/#/payment/success',
      cancel_url: window.location.origin + '/#/account/abonnements', //'https://localhost:3000/#/account/abonnements',
    };
    const colRef = collection(
      this.db,
      `users/${this.auth.user?.uid}/checkout_sessions`
    );
    return addDoc(colRef, Object.assign({}, sessionObj));
  }
}
