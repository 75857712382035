import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import 'zone.js'; // Required for Angular
import { environment } from './environments/environment';
import { enableProdMode } from '@angular/core';
import { Utilities } from '@microsoft/office-js-helpers';

declare const Office: any;
if (environment.production) {
  enableProdMode();
}

/* bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
); */

/* Office.initialize = (reason: any) => {
  //@ts-ignore
  window.history.replaceState = window._historyCache.replaceState;
  //@ts-ignore
  window.history.pushState = window._historyCache.pushState;
  //document.getElementById('sideload-msg').style.display = 'none';
  console.log('office is initialized', reason);
  Office.onReady(async function () {
    console.log('Office is ready');
  });
  // Bootstrap the app
  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
  );
}; */

if (Office && Utilities.platform !== undefined) {
  // see: https://github.com/OfficeDev/office-js/issues/246#issuecomment-437260860
  Office.initialize = function () {
    console.log('Bootstrapping Angular, with OfficeJS');

    //@ts-ignore
    window.history.replaceState = window._historyCache.replaceState;
    //@ts-ignore
    window.history.pushState = window._historyCache.pushState;
    // Does not throw. ever. see: https://github.com/OfficeDev/office-js/issues/246#issuecomment-438342679
    bootstrapApplication(AppComponent, appConfig).catch((err) =>
      console.error(err)
    );
    /* Office.onReady()
      .then((info: any) => {
        return bootstrapApplication(AppComponent, appConfig);
      })
      .catch((reason: any) => {
        console.warn('Angular not bootstrapped. reason: \n');
        console.log(reason);
      }); */
  };
} else {
  console.log('Bootstrapping Angular, without OfficeJS');
  bootstrapApplication(AppComponent, appConfig).catch((err) =>
    console.error(err)
  );
}
