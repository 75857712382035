import { CommonModule, registerLocaleData } from '@angular/common';
import { Component, inject, NgZone } from '@angular/core';
import {
  Auth,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  sendEmailVerification,
  signInWithEmailAndPassword,
  signOut,
  User,
} from '@angular/fire/auth';
import { FormsModule } from '@angular/forms';
import { RouterLinkWithHref, RouterOutlet } from '@angular/router';
import * as OfficeHelpers from '@microsoft/office-js-helpers';
import * as de from '@angular/common/locales/de';
import { TranslateService } from '@ngx-translate/core';
import { BackendService } from './service/backend.service';

declare const Office: any;
declare const Word: any;
let dialog: any;

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, FormsModule, RouterOutlet, RouterLinkWithHref],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'kostenmarke_addin';
  private auth: Auth = inject(Auth);

  constructor(
    private zone: NgZone,
    public translate: TranslateService,
    private bs: BackendService
  ) {
    this.translate.setDefaultLang('de');
    this.bs.switchLanguage(this.translate.getBrowserLang() || 'de');
    console.log('Browser lang', this.translate.getBrowserLang());

    registerLocaleData(de.default);
    /* this.zone.run(() => {
      Office.onReady(function () {
        //localStorage.setItem("key", "value");
      });
    }); */
  }
}

/* async function openDialog() {
  console.log('openDialog');

  await Office.context.ui.displayDialogAsync('https://hjheeg.de', {
    height: 30,
    width: 20,
    displayInIframe: true,
  });
} */
function processMessage(arg: any) {
  const messageFromDialog = JSON.parse(arg.message);
  console.log('usernmae', messageFromDialog.name);
}
