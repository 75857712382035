@if (auth.user && !auth.user.emailVerified) {
<div class="alert alert-warning" role="alert">
  Bitte bestätigen Sie Ihre E-Mail über den Link in der Willkommens-Email.<br>
  <!-- <button class="btn btn-sm btn-warning" (click)="auth.resendVerification()"
      style="--bs-btn-padding-y: .25rem; --bs-btn-padding-x: .5rem; --bs-btn-font-size: .75rem;">Resend
      Verification Email</button> -->
</div>
}

<div class="container-fluid main-wrapper" data-bs-theme="light">
  @if(showLoading) {
  <div class="backdrop">
    <div class="spinner-container">
      <div class="spinner-border text-primary" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div class="spinner-text">{{loadingText}}</div>
    </div>
  </div>
  }
  <app-navbar></app-navbar>
  <!-- <div class="content h-100" style="padding-bottom:60px;"> -->
  <div>
    <router-outlet />
  </div>
  <app-footer></app-footer>
</div>